<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
			@clearSearch="clearSearch">
			<!--搜索自定义内容-->
			<!--			<el-form-item slot="search-item">-->
			<!--				<el-select v-model="searchFormData.device_id" filterable remote reserve-keyword clearable size="small" placeholder="按设备名称查询" :remote-method="remoteDevice" :loading="s2Loading">-->
			<!--					<el-option v-for="item in sDevice" :key="item.device_id" :label="item.device_name" :value="item.device_id">-->
			<!--					</el-option>-->
			<!--				</el-select>-->
			<!--			</el-form-item>-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" clearable placeholder="按订单号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" style="width: 150px;" clearable
					placeholder="按手机号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" style="width: 150px;" clearable
					placeholder="按设备编号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.order_status" placeholder="按订单状态查询" clearable size="small">
					<el-option label="进行中" :value="10">
					</el-option>
					<el-option label="已取消" :value="20">
					</el-option>
					<el-option label="已完成" :value="30">
					</el-option>
				</el-select>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="uid" title="用户信息" align="center" min-width="100px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="device_id" title="消费设备" align="center" min-width="100px">
				<template v-slot="{ row }">
					<div v-if="!row.device_id">无</div>
					<div v-if="row.device_id">{{row.device_name}}</div>
					<div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}-{{row.plug_num}}</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="group_name" title="消费站点" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_type" title="支付类型" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.pay_type[row.pay_type] + (row.cardno ? '-在线卡' : '')||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="pay_type" title="支付方式" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span v-if="row.order_ly==10">微信小程序</span>
					<span v-if="row.order_ly==20">支付宝小程序</span>
					<span v-if="row.order_ly==30">农行</span>
					<span v-if="row.order_ly==50">微信APP</span>
					<span v-if="row.order_ly==60">支付宝APP</span>
				</template>
			</vxe-table-column>
			<!--			<vxe-table-column slot="table-item" field="after_balance" title="账户余额" align="center" min-width="80px" />-->
			<vxe-table-column slot="table-item" field="order_price" title="订单金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_price" title="实际付款金额" align="center" min-width="80px" />
			<!--			<vxe-table-column slot="table-item" field="yh_price" title="优惠金额" align="center" min-width="80px" />-->
			<!--			<vxe-table-column slot="table-item" field="pay_status" title="支付状态" align="center" min-width="80px">-->
			<!--				<template v-slot="{ row }">-->
			<!--					<span>{{types.pay_status[row.pay_status]||'未知状态'}}</span>-->
			<!--				</template>-->
			<!--			</vxe-table-column>-->

			<vxe-table-column slot="table-item" field="electric" title="实际用电量" align="center" width="100px" />
			<vxe-table-column slot="table-item" field="duration" title="实际时长" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{$common.secondToTime(row.duration)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="coupon_id" title="优惠券ID" align="center" width="100px" />
			<vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" width="100px" />
			<vxe-table-column slot="table-item" field="orde_status" title="订单状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.order_status[row.order_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<!--			<vxe-table-column slot="table-item" field="coupon_id" title="优惠券" align="center" width="100px"/>-->
			<vxe-table-column slot="table-item" title="操作" align="center" width="130">
				<template v-slot="{ row }">
					<!--					<el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('user.order/view')">详情</el-button>-->
					<!--					<el-button size="small" plain @click="workOne(row)" v-if="row.order_status==30&&$hasAccess('user.order/workorder')">日志</el-button>-->
					<el-button size="small" plain @click="workOne(row)"
						v-if="row.order_status!=30&&$hasAccess('user.order/workorder')">记录</el-button>
					<!--					<el-button size="small" plain @click="refundOne(row)" v-if="row.u_type==10&&row.pay_status==20&&$hasAccess('user.order/refund')&&row.pay_price>0">退款</el-button>-->
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 详情 -->
		<el-dialog title="消费详情" :visible.sync="detailDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="1200px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-row>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:1}">
						<!-- 订单信息 -->
						<div class="el-block">
							<div class="row-title">订单信息</div>
							<el-form-item label="订单号：">{{detailDialogFormData.order_no}}</el-form-item>
							<el-form-item label="订单状态：">
								{{types.order_status[detailDialogFormData.order_status]||'未知状态'}}</el-form-item>
							<el-form-item label="智能充电订单：">{{types.is_auto[detailDialogFormData.is_auto]||'未知'}}
							</el-form-item>
							<el-form-item label="订单金额：">{{detailDialogFormData.order_price}}元</el-form-item>
							<!-- <el-form-item label="下单时间：">{{detailDialogFormData.creat_time}}</el-form-item> -->
							<el-form-item label="订单时间：">{{detailDialogFormData.start_time}}
								-<br />{{detailDialogFormData.end_time}}</el-form-item>
							<el-form-item label="订单时长：">{{$common.secondToTime(detailDialogFormData.order_duration*60)}}
							</el-form-item>
							<el-form-item label="订单功率：">{{detailDialogFormData.order_power}}</el-form-item>
							<el-form-item label="实际充电平均功率：">{{detailDialogFormData.power}}</el-form-item>
							<el-form-item label="实际用电量：">{{detailDialogFormData.electric}}</el-form-item>
							<el-form-item label="实际时长：">{{$common.secondToTime(detailDialogFormData.duration)}}
							</el-form-item>
							<el-form-item label="账户余额：">{{detailDialogUserBalance}}元</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
						<!-- 支付信息 -->
						<div class="el-block">
							<div class="row-title">支付信息</div>
							<el-form-item label="消费用户：">
								{{detailDialogFormData.u_type==10?'微信-'+(detailDialogFormData.nickname||'匿名用户'):'电卡-'+detailDialogFormData.cardno}}
							</el-form-item>
							<el-form-item label="支付状态：">{{types.pay_status[detailDialogFormData.pay_status]||'未知状态'}}
							</el-form-item>
							<el-form-item label="支付类型：">
								{{types.pay_type[detailDialogFormData.pay_type] + (detailDialogFormData.cardno ? '-在线卡' : '')||'未知类型'}}
							</el-form-item>
							<el-form-item v-if="detailDialogFormData.cardno" label="刷卡卡号：">
								{{detailDialogFormData.cardno}}</el-form-item>
							<el-form-item label="实际支付金额：">{{detailDialogFormData.pay_price}}元</el-form-item>
							<el-form-item label="微信支付金额：">{{detailDialogFormData.wx_price}}元</el-form-item>
							<el-form-item label="支付时间：">{{detailDialogFormData.pay_time}}</el-form-item>
						</div>
						<!-- 设备信息 -->
						<div class="el-block">
							<div class="row-title">设备信息</div>
							<el-form-item label="设备名称：">
								{{detailDialogFormData.device_name}}<br />{{detailDialogFormData.device_no}}
							</el-form-item>
							<el-form-item label="插座序号：">
								{{detailDialogFormData.plug_num}}（ID：{{detailDialogFormData.plug_id}}）</el-form-item>
							<el-form-item label="异常原因：">
								{{types.unusual_status[detailDialogFormData.unusual_status]||'未知原因'}}</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
						<!-- 优惠信息 -->
						<div class="el-block">
							<div class="row-title">优惠信息</div>
							<el-form-item label="消费优惠ID：">
								{{detailDialogFormData.gift_num?detailDialogFormData.gift_id:'无'}}</el-form-item>
							<el-form-item label="优惠数量：">{{detailDialogFormData.gift_num||0}}</el-form-item>
						</div>
						<!-- 代理信息 -->
						<div class="el-block">
							<div class="row-title">代理信息</div>
							<el-form-item label="代理商ID：">{{detailDialogFormData.agent_id||'无'}}</el-form-item>
							<el-form-item label="分销金额：">{{detailDialogFormData.share_price}}元</el-form-item>
							<el-form-item label="分销结算状态：">
								{{types.share_status[detailDialogFormData.share_status]||'未知状态'}}</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 日志 -->
		<el-dialog title="设备交互日志" :visible.sync="workDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" :fullscreen="true">
			<vxe-table border highlight-hover-row resizable align="center" :data="workDialogFormData">
				<vxe-table-column type="seq" title="#" width="60"></vxe-table-column>
				<vxe-table-column field="time" title="时间" width="160">
					<template v-slot="{ row }">
						<span>{{$common.stampToDatetime(row.time*1000)}}</span>
					</template>
				</vxe-table-column>
				<vxe-table-column field="describe" title="说明" align="left" min-width="300"></vxe-table-column>
				<vxe-table-column field="type" title="传输" width="130" :formatter="formatType"></vxe-table-column>
				<vxe-table-column field="model" title="指令编号" width="85"></vxe-table-column>
				<vxe-table-column field="content" title="源指令" width="340" align="left"></vxe-table-column>
			</vxe-table>
		</el-dialog>

		<!-- 退款 -->
		<el-dialog title="消费退款" :visible.sync="refundDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="450px">
			<el-form :model="refundDialogFormData" :rules="refundRules" size="mini" ref="refundForm" label-width="120px"
				class="form">
				<el-form-item label="订单号：">{{refundDialogFormOriData.order_no}}</el-form-item>
				<el-form-item label="实际付款金额：">{{refundDialogFormOriData.pay_price}}元</el-form-item>
				<el-form-item label="退款方式：" prop="refund_for">
					<el-radio-group v-model="refundDialogFormData.refund_for">
						<el-radio :label="10">钱包</el-radio>
						<el-radio :label="30">现金</el-radio>
						<el-radio :label="40">赠送金</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="退款金额：" prop="refund_price">
					<el-input-number v-model="refundDialogFormData.refund_price" size="small" style="width: 80%;"
						controls-position="right" :min="0.01" :max="refundDialogFormOriData.pay_price" :step="1"
						:precision="2">
					</el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRefund()"
					v-if="refundDialogFormOriData.pay_status==20&&$hasAccess('user.order/refund')">确 认 退 款
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'user-order-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '消费列表',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					u_type: 10
				},
				types: {
					pay_status: {
						10: '未支付',
						20: '已支付',
						30: '退款'
					},
					pay_type: {
						10: '钱包',
						20: '电卡',
						30: '先付后充',
						40: '赠送金'
					},
					order_status: {
						10: '进行中',
						20: '已取消',
						30: '已完成'
					},
					unusual_status: {
						10: '正常',
						20: '功率异常',
						30: '插座脱落',
						40: '信号异常'
					},
					is_auto: {
						10: '是',
						20: '否'
					},
					share_status: {
						10: '不结算',
						20: '未结算',
						30: '已结算'
					}
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: [],
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				detailDialogUserBalance: 0,
				// 日志
				workDialogShow: false,
				workDialogFormData: {},
				// 退款
				refundDialogShow: false,
				refundDialogFormData: {},
				refundDialogFormOriData: {},
				refundRules: {
					refund_price: [{
						required: true,
						message: '请填写退款金额',
						trigger: 'change'
					}],
					refund_for: [{
						required: true,
						message: '请选择退款方式',
						trigger: 'change'
					}]
				}
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!searchData.group_id && group_id) {
					searchData.group_id = group_id
				}
				const res = await this.$api.Ware.GetOrderList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					u_type: 10
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
			// 详情
			async detailOne(row) {
				const params = {
					token: this.$store.state.user.token,
					order_id: row.order_id
				}
				const res = await this.$api.Ware.GetOrderDetail(params)
				this.detailDialogFormData = res
				this.detailUserOne(res.uid)
				this.detailDialogShow = true
			},
			// 用户详情
			async detailUserOne(id) {
				const params = {
					token: this.$store.state.user.token,
					fans_id: id
				}
				const res = await this.$api.Ware.GetFansDetail(params)
				this.detailDialogUserBalance = res.balance
			},
			// 日志
			async workOne(row) {
				this.isLoading = true;
				const params = {
					token: this.$store.state.user.token,
					order_id: row.order_id
				}
				const res = await this.$api.Ware.GetWorkOrder(params)
				this.workDialogFormData = res.log_content
				this.workDialogShow = true
				this.isLoading = false;
			},
			// 列表格式化
			formatType({
				cellValue
			}) {
				if (cellValue == 'server') {
					return '服务器 => 硬件'
				}
				if (cellValue == 'client') {
					return '硬件 => 服务器'
				}
				return '未知'
			},
			// 退款
			refundOne(row) {
				this.refundDialogFormData = {
					order_id: row.order_id,
					refund_price: row.pay_price,
					refund_for: 40
				}
				this.refundDialogFormOriData = {
					pay_price: Number(row.pay_price),
					pay_status: row.pay_status,
					order_no: row.order_no
				}
				this.refundDialogShow = true
			},
			// 退款保存
			saveRefund() {
				this.$refs.refundForm.validate(async (valid) => {
					if (valid) {
						this.closeDialog()
						this.isLoading = true;
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.refundDialogFormData)
						}
						await this.$api.Ware.RefundOrder(params)
						this.$notify({
							title: '成功',
							message: '退款成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 用户名查询
			async remoteFans(query) {
				if (query !== '') {
					this.sLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						nickname: query
					}
					const res = await this.$api.Ware.GetFansList(params, searchData)
					this.sLoading = false
					this.sFans = res.data
				} else {
					this.sFans = []
				}
			},
			// 设备名查询
			async remoteDevice(query) {
				if (query !== '') {
					this.s2Loading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						device_name: query
					}
					const res = await this.$api.Device.GetDeviceList(params, searchData)
					this.s2Loading = false
					this.sDevice = res.data
				} else {
					this.sDevice = []
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
